<template>
  <div id="cashback">
    <div class="content_box">
      <div class="inner_max">
        <h3>{{ $t('promotion.cashback.title.program') }}</h3>
        <div class="function_content">
          <div>
            <span class="title">
              <img src="@/assets/images/promotion/cashback/wallet.png" alt="wallet icon" />
              <span>{{ $t('promotion.cashback.subTitle.bonus') }} </span>
              <b>{{ $t('promotion.cashback.subTitle.wallet') }}</b>
            </span>
            <div class="cashBackContent">
              <div>
                <p>
                  <span>$</span>
                  <span class="big" translate="no">{{ amountTemplate(bonus)[0] }}</span>
                  <span>.</span>
                  <span translate="no">{{ amountTemplate(bonus)[1] }}</span>
                </p>
                <el-popover placement="top" width="200" trigger="hover">
                  <img
                    slot="reference"
                    class="infoIcon"
                    src="@/assets/images/promotion/cashback/information.png"
                    alt="info Icon"
                  />
                  <span class="popover_content">{{ $t('promotion.cashback.information.availableBonus2') }}</span>
                </el-popover>
              </div>
              <p class="sub_title green">{{ $t('promotion.cashback.subTitle.availableBonus') }}</p>
            </div>
            <el-button @click="$router.push('applyBonus')">
              {{ $t('promotion.cashback.button.applyForBonus') }}
            </el-button>
          </div>

          <img src="@/assets/images/promotion/cashback/three_green_arrow.png" alt="three green arrow" />

          <div>
            <span class="title">
              <img src="@/assets/images/promotion/cashback/cashback.png" alt="cashback icon" />
              <span>{{ $t('promotion.cashback.subTitle.cashback') }} </span>
              <b>{{ $t('promotion.cashback.subTitle.wallet') }}</b>
            </span>
            <div class="cashBackContent_box">
              <div>
                <div>
                  <p>
                    <span>$</span>
                    <span class="big" translate="no">{{ amountTemplate(totalCashback)[0] }}</span>
                    <span>.</span>
                    <span translate="no">{{ amountTemplate(totalCashback)[1] }}</span>
                  </p>
                  <el-popover placement="top" width="200" trigger="hover">
                    <img
                      slot="reference"
                      class="infoIcon"
                      src="@/assets/images/promotion/cashback/information.png"
                      alt="info Icon"
                    />
                    <span class="popover_content">{{ $t('promotion.cashback.information.totalCashback') }}</span>
                  </el-popover>
                </div>
                <p class="sub_title white">{{ $t('promotion.cashback.subTitle.totalCashback') }}</p>
              </div>
              <div>
                <div>
                  <p>
                    <span>$</span>
                    <span class="big" translate="no">{{ amountTemplate(pendingCashback)[0] }}</span>
                    <span>.</span>
                    <span translate="no">{{ amountTemplate(pendingCashback)[1] }}</span>
                  </p>
                  <el-popover placement="top" width="200" trigger="hover">
                    <img
                      slot="reference"
                      class="infoIcon"
                      src="@/assets/images/promotion/cashback/information.png"
                      alt="info Icon"
                    />
                    <span class="popover_content">{{ $t('promotion.cashback.information.februaryCashback') }}</span>
                  </el-popover>
                </div>
                <p class="sub_title gray">{{ $t('promotion.cashback.subTitle.FebruaryCashback') }}</p>
              </div>
            </div>

            <div class="cashBackContent_box">
              <div>
                <div>
                  <p>
                    <span>$</span>
                    <span class="big" translate="no">{{ amountTemplate(availableCashback)[0] }}</span>
                    <span>.</span>
                    <span translate="no">{{ amountTemplate(availableCashback)[1] }}</span>
                  </p>
                  <el-popover placement="top" width="200" trigger="hover">
                    <img
                      slot="reference"
                      class="infoIcon"
                      src="@/assets/images/promotion/cashback/information.png"
                      alt="info Icon"
                    />
                    <span class="popover_content">{{ $t('promotion.cashback.information.cashbackRedeemable') }}</span>
                  </el-popover>
                </div>
                <p class="sub_title green">{{ $t('promotion.cashback.subTitle.cashbackRedeemable') }}</p>
              </div>
              <el-button @click="openDialog">{{ $t('promotion.cashback.button.redeemCashback') }}</el-button>
            </div>
          </div>
        </div>

        <img class="greenArrow" src="@/assets/images/promotion/cashback/green_arrow.png" alt="green arrow" />
        <h3>{{ $t('promotion.cashback.title.cashSummary') }}</h3>
        <div class="table_box">
          <el-table :data="cashbackSummaries" stripe max-height="350">
            <el-table-column prop="date" :label="$t('promotion.cashback.column.date')" min-width="100" />
            <el-table-column prop="mt4Account" :label="$t('promotion.cashback.column.account')" min-width="100">
              <template slot-scope="scope">{{ `# ${scope.row.mt4Account}` }}</template>
            </el-table-column>
            <el-table-column prop="amount" :label="$t('promotion.cashback.column.redeem')" min-width="100">
              <template slot-scope="scope">{{ `$ ${splitThousands(scope.row.amount)}` }}</template>
            </el-table-column>
          </el-table>
        </div>

        <img class="greenArrow" src="@/assets/images/promotion/cashback/green_arrow.png" alt="green arrow" />

        <div class="per_trades_info">
          <h3>{{ $t('promotion.cashback.title.howMuch') }}</h3>
          <PerTradesInfo :stpRatio="stpRatio" :ecnRatio="ecnRatio" />
        </div>

        <h3>{{ $t('promotion.cashback.title.faqs') }}</h3>
        <el-collapse accordion>
          <el-collapse-item
            v-for="index in 24"
            :key="index"
            :title="$t(`promotion.cashback.faqsList.question_${index}`)"
            :name="index"
          >
            <span v-html="$t(`promotion.cashback.faqsList.answer_${index}`, { email: email })" />
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <el-dialog
      :visible.sync="showDialog"
      :top="'10%'"
      :width="'500px'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="vDialog"
    >
      <div class="dialog_content">
        <span>{{ $t('promotion.cashback.title.redeemCashback') }}</span>
        <span>{{ $t('promotion.cashback.content.electTheDestinationAccount') }}:</span>
        <span>{{ $t('promotion.cashback.title.cashbackBalance') }}:</span>
        <span class="balance">{{ `$${splitThousands(availableCashback)}` }}</span>
        <div class="redeemForm">
          <div class="redeemForm_InfoBlock">
            <div class="dialogSubTitle">
              <span>{{ $t('promotion.cashback.subTitle.account') }}</span>
              <el-popover placement="top" width="200" trigger="hover">
                <img
                  slot="reference"
                  class="infoIcon"
                  src="@/assets/images/promotion/cashback/information.png"
                  alt="info Icon"
                />
                <span class="popover_content">{{ $t('promotion.cashback.information.selectTheTradingAccount') }}</span>
              </el-popover>
            </div>
            <el-select v-model="selectAccount" data-testid="account">
              <el-option
                v-for="item in mt4Accounts"
                :key="item.mt4Account"
                :value="item.mt4Account"
                :label="`${item.mt4Account}-${item.currency}`"
                :data-testid="item.mt4Account"
              />
            </el-select>
          </div>

          <div class="redeemForm_InfoBlock">
            <div class="dialogSubTitle">
              <span>{{ $t('promotion.cashback.subTitle.amount') }}</span>
              <el-popover placement="top" width="200" trigger="hover">
                <img
                  slot="reference"
                  class="infoIcon"
                  src="@/assets/images/promotion/cashback/information.png"
                  alt="info Icon"
                />
                <span class="popover_content">{{ $t('promotion.cashback.information.enterTheAmount') }}</span>
              </el-popover>
            </div>
            <div class="amountInput">
              <span>{{ splitThousands(availableCashback) }}</span>
              <span>USD</span>
            </div>
            <span class="rateInfo">
              {{
                `${rateAmount} ${selectAccountCurrency} 
                  (1USD=${selectAccountRete} ${selectAccountCurrency})`
              }}
            </span>
          </div>
        </div>
        <el-button @click="redeem">{{ $t('promotion.cashback.button.redeemCashback') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiCampaignInfo, apiSubmitRedeem } from '@/resource';
import NumericInput from '@/components/NumericInput';
import PerTradesInfo from '@/components/cashback/PerTradesInfo';
import splitThousands from '@/util/splitThousands';

export default {
  name: 'Cashback',
  components: { NumericInput, PerTradesInfo },
  data() {
    return {
      stpRatio: [],
      ecnRatio: [],
      cashbackSummaries: [],
      mt4Accounts: [],
      bonus: 0,
      availableCashback: 0,
      pendingCashback: 0,
      totalCashback: 0,
      selectAccount: '',
      selectAccountCurrency: '',
      selectAccountRete: 1,
      showDialog: false
    };
  },
  computed: {
    campaignID() {
      let campaign = this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === 'CASHBACK_2');
      return campaign ? campaign.id : null;
    },
    rateAmount() {
      const amount = (this.availableCashback * this.selectAccountRete).toFixed(2);
      return splitThousands(amount);
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    email() {
      return this.$platform.info.getEmail(this.regulator);
    }
  },
  watch: {
    mt4Accounts(accountsArray) {
      // set the account of index 0 for default account
      this.selectAccount = accountsArray[0].mt4Account;
    },
    selectAccount(value) {
      this.selectAccountCurrency = this.mt4Accounts.find(item => item.mt4Account === value)?.currency;
      this.selectAccountRete = this.mt4Accounts.find(item => item.mt4Account === value)?.rate;
    }
  },
  methods: {
    getCashbackInfo() {
      apiCampaignInfo(this.campaignID)
        .then(resp => {
          if (resp.data.code === 0) {
            this.bonus = resp.data.data.bonus;
            this.availableCashback = resp.data.data.availableCashback;
            this.pendingCashback = resp.data.data.pendingCashback;
            this.totalCashback = resp.data.data.totalCashback;
            this.stpRatio = resp.data.data.stpRatio;
            this.ecnRatio = resp.data.data.ecnRatio;
            this.cashbackSummaries = resp.data.data.cashbackSummaries;
            this.mt4Accounts = resp.data.data.mt4Accounts;
          }
        })
        .catch(error => {
          this.showError();
        });
    },
    redeem() {
      if (this.availableCashback === 0) {
        this.showError(this.$t('promotion.cashback.zeroMsg'));
      } else {
        apiSubmitRedeem({
          campaignId: this.campaignID,
          mt4Account: this.selectAccount
        })
          .then(resp => {
            if (resp.data.data) {
              this.showSuccess();
              this.closeDialog();
              this.getCashbackInfo();
            } else {
              this.showError();
            }
          })
          .catch(error => {
            this.showError();
          });
      }
    },
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    showSuccess() {
      this.$message({
        message: this.$t('common.field.successful'),
        type: 'success',
        duration: 5000
      });
    },
    showError(errorMsg) {
      this.$message({
        message: errorMsg || this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    amountTemplate(amount) {
      return splitThousands(amount).toString().split('.');
    },
    splitThousands
  },
  mounted() {
    this.getCashbackInfo();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/cashback.scss';
</style>
